////////////Importations
////////////Importations

import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

////////////Curseur
////////////Curseur

const invertedCursor = document.getElementById("invertedcursor");
const invertedcursorHover = document.getElementById("invertedcursor-hover");

document.addEventListener("mousemove", (e) => {
  const x = e.clientX;
  const y = e.clientY;
  invertedCursor.style.left = `${x}px`;
  invertedCursor.style.top = `${y}px`;
});

document.querySelectorAll(".b-curseur").forEach((element) => {
  element.addEventListener("mouseenter", () => {
    invertedCursor.style.transform = "scale(1.6)";
  });
  element.addEventListener("mouseleave", () => {
    invertedCursor.style.transform = "scale(1)";
  });
});

////////////Parallaxe
////////////Parallaxe

var parallax = document.querySelector(".parallax");
var heroImage = parallax;

function setTranslate(xPos, yPos, el) {
  el.style.transform = "translate3d(" + xPos + ", " + yPos + "px, 0)";
}

window.addEventListener("DOMContentLoaded", scrollLoop, false);

var xScrollPosition;
var yScrollPosition;

function scrollLoop() {
  xScrollPosition = window.scrollX;
  yScrollPosition = window.scrollY;

  setTranslate(0, yScrollPosition * -0.1, heroImage);

  requestAnimationFrame(scrollLoop);
}

////////////Loader
////////////Loader

// document.addEventListener("DOMContentLoaded", function () {
//   // Assurez-vous que les images sont chargées avant d'afficher le contenu
//   var images = document.querySelectorAll("#content img");
//   var imagesLoaded = 0;
//   var totalImages = images.length;

//   if (totalImages === 0) {
//     // Si aucune image n'est trouvée, lancez l'animation directement
//     animateLoading();
//   } else {
//     // Sinon, attendez que toutes les images soient chargées
//     images.forEach(function (image) {
//       image.addEventListener("load", function () {
//         imagesLoaded++;
//         if (imagesLoaded === totalImages) {
//           animateLoading();
//         }
//       });
//     });
//   }

//   function animateLoading() {
//     $("#loading").animate(
//       {
//         top: "-100%",
//       },
//       500,
//       function () {
//         $(this).hide();
//       }
//     );
//     $("#content").show();
//   }
// });
////////////Loader
////////////Loader
document.addEventListener("DOMContentLoaded", function () {
  // Attendez que toutes les vidéos et images soient chargées
  var videos = document.querySelectorAll("#content video");
  var images = document.querySelectorAll("#content img");
  var totalResources = videos.length + images.length;
  var loadedResources = 0;

  if (totalResources === 0) {
    // Si aucune ressource n'est trouvée, lancez l'animation directement
    animateLoading();
  } else {
    // Sinon, attendez que toutes les ressources soient chargées
    videos.forEach(function (video) {
      video.addEventListener("canplaythrough", function () {
        loadedResources++;
        if (loadedResources === totalResources) {
          animateLoading();
        }
      });
    });

    images.forEach(function (image) {
      image.addEventListener("load", function () {
        loadedResources++;
        if (loadedResources === totalResources) {
          animateLoading();
        }
      });
    });
  }

  function animateLoading() {
    $("#loading").animate(
      {
        top: "-100%",
      },
      500,
      function () {
        $(this).hide();
      }
    );
    $("#content").show();
  }
});
